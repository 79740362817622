<address *ngIf="address">

  <!-- owner info -->
  <ng-container *ngIf="address.companyName">
    <span class="company-name">{{address.companyName}}</span><br>
  </ng-container>
  <ng-container *ngIf="address.firstName && address.lastName">
    <span class="person-name">{{address.title ? address.title + ' ' : ''}}{{address.firstName + ' ' + address.lastName}}</span><br>
  </ng-container>
  <ng-container *ngIf="address.contactPerson"><span class="contact-person">{{address.contactPerson}}</span><br></ng-container>
  <!-- location info -->
  <ng-container *ngIf="address.remarks">{{address.remarks}}<br></ng-container>
  <ng-container *ngIf="address.line1">{{address.line1}}<br></ng-container>
  <ng-container *ngIf="address.line2">{{address.line2}}<br></ng-container>
  <ng-container *ngIf="address.postalCode && address.town">{{address.postalCode}} {{address.town}}<br></ng-container>
  <ng-container *ngIf="address.country">{{address.country.name}}<br></ng-container>
</address>
