import { Component, Input } from '@angular/core';
import { KurzAddress } from '../types/kurz-adress.interface';

@Component({
  selector: 'app-kurz-address',
  templateUrl: './kurz-address.component.html',
  styleUrls: ['./kurz-address.component.scss']
})
export class KurzAddressComponent {
  @Input()
  address: KurzAddress;
}
